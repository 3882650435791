var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medium-10 column signup"},[_c('div',{staticClass:"text-center medium-12 signup--hero"},[_c('img',{staticClass:"hero--logo",attrs:{"src":_vm.globalConfig.logo,"alt":_vm.globalConfig.installationName}}),_vm._v(" "),_c('h2',{staticClass:"hero--title"},[_vm._v("\n      "+_vm._s(_vm.$t('REGISTER.TRY_WOOT'))+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"row align-center"},[_c('div',{staticClass:"small-12 medium-6 large-5 column"},[_c('form',{staticClass:"signup--box login-box",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('woot-input',{class:{ error: _vm.$v.credentials.fullName.$error },attrs:{"label":_vm.$t('REGISTER.FULL_NAME.LABEL'),"placeholder":_vm.$t('REGISTER.FULL_NAME.PLACEHOLDER'),"error":_vm.$v.credentials.fullName.$error
              ? _vm.$t('REGISTER.FULL_NAME.ERROR')
              : ''},on:{"blur":_vm.$v.credentials.fullName.$touch},model:{value:(_vm.credentials.fullName),callback:function ($$v) {_vm.$set(_vm.credentials, "fullName", $$v)},expression:"credentials.fullName"}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.credentials.email.$error },attrs:{"type":"email","label":_vm.$t('REGISTER.EMAIL.LABEL'),"placeholder":_vm.$t('REGISTER.EMAIL.PLACEHOLDER'),"error":_vm.$v.credentials.email.$error ? _vm.$t('REGISTER.EMAIL.ERROR') : ''},on:{"blur":_vm.$v.credentials.email.$touch},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"credentials.email"}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.credentials.accountName.$error },attrs:{"label":_vm.$t('REGISTER.ACCOUNT_NAME.LABEL'),"placeholder":_vm.$t('REGISTER.ACCOUNT_NAME.PLACEHOLDER'),"error":_vm.$v.credentials.accountName.$error
              ? _vm.$t('REGISTER.ACCOUNT_NAME.ERROR')
              : ''},on:{"blur":_vm.$v.credentials.accountName.$touch},model:{value:(_vm.credentials.accountName),callback:function ($$v) {_vm.$set(_vm.credentials, "accountName", $$v)},expression:"credentials.accountName"}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.credentials.password.$error },attrs:{"type":"password","label":_vm.$t('LOGIN.PASSWORD.LABEL'),"placeholder":_vm.$t('SET_NEW_PASSWORD.PASSWORD.PLACEHOLDER'),"error":_vm.passwordErrorText},on:{"blur":_vm.$v.credentials.password.$touch},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"credentials.password"}}),_vm._v(" "),(_vm.globalConfig.hCaptchaSiteKey)?_c('div',{staticClass:"h-captcha--box"},[_c('vue-hcaptcha',{ref:"hCaptcha",class:{ error: !_vm.hasAValidCaptcha && _vm.didCaptchaReset },attrs:{"sitekey":_vm.globalConfig.hCaptchaSiteKey},on:{"verify":_vm.onRecaptchaVerified}}),_vm._v(" "),(!_vm.hasAValidCaptcha && _vm.didCaptchaReset)?_c('span',{staticClass:"captcha-error"},[_vm._v("\n            "+_vm._s(_vm.$t('SET_NEW_PASSWORD.CAPTCHA.ERROR'))+"\n          ")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('woot-submit-button',{attrs:{"disabled":_vm.isSignupInProgress || !_vm.hasAValidCaptcha,"button-text":_vm.$t('REGISTER.SUBMIT'),"loading":_vm.isSignupInProgress,"button-class":"large expanded"}}),_vm._v(" "),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.termsLink),expression:"termsLink"}],staticClass:"accept--terms"})],1),_vm._v(" "),_c('div',{staticClass:"column text-center sigin--footer"},[_c('span',[_vm._v(_vm._s(_vm.$t('REGISTER.HAVE_AN_ACCOUNT')))]),_vm._v(" "),_c('router-link',{attrs:{"to":"/app/login"}},[_vm._v("\n          "+_vm._s(_vm.useInstallationName(
              _vm.$t('LOGIN.TITLE'),
              _vm.globalConfig.installationName
            ))+"\n        ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }